<template>
  <div class="bg-white rounded shadow p-2">
    <table-spinner :loading="loading"></table-spinner>
    <div v-if="!loading && emailTemplate">
      <div class="d-flex">
        <feather-icon icon="MailIcon" size="19" />
        <h4 class="mb-0 ml-50">Email template information</h4>
      </div>
      <div class="mt-1">
        <b-row class="ml-1">
          Email template ID:
          <span class="ml-1"
            ><b>{{ emailTemplate.id }}</b></span
          >
        </b-row>
        <b-row class="mt-4">
          <b-col cols="12" lg="5" md="8">
            <label for="email-subject">Email subject:</label>
            <b-form-input id="email-subject" v-model="emailTemplate.subject" />
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <label for="email-content">Email content:</label>
            <quill-editor v-if="showEdit" v-model="emailTemplate.content" />
            <div
              v-if="!showEdit"
              id="email-content"
              v-html="emailTemplate.content"
            ></div>
            <edit-button
              :disabled="loading"
              @click="showEdit = !showEdit"
            /> </b-col
        ></b-row>
        <b-col class="mr-auto ml-auto mt-4" cols="12" lg="8" md="8" sm="10">
          <b-button variant="primary" class="w-100" @click="onSaveEmailData"
            >Save</b-button
          >
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";

import { BCol, BRow, BFormInput, BButton } from "bootstrap-vue";
import { fetchEmailTemplate } from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import { quillEditor } from "vue-quill-editor";
import EditButton from "@/layouts/components/buttons/EditButton";
import { mapActions } from "vuex";

export default {
  components: {
    BCol,
    BRow,
    BFormInput,
    BButton,
    TableSpinner,
    quillEditor,
    EditButton,
  },
  data() {
    return {
      loading: false,
      emailTemplate: null,
      showEdit: false,
    };
  },
  mounted() {
    this.onFetchEmailTemplateData();
  },
  created() {
    this.$watch(
      () => this.$route.params.id,
      () => {
        this.onFetchEmailTemplateData();
      }
    );
  },
  methods: {
    ...mapActions({
      putEmailTemplate: "adminEmailTemplate/putEmailTemplate",
    }),
    async onFetchEmailTemplateData() {
      this.loading = true;
      try {
        this.emailTemplate = await fetchEmailTemplate(this.$route.params.id);
      } catch (e) {
        apiErrorHandler(e);
        this.$router.go(-1);
      } finally {
        this.loading = false;
      }
    },
    async onSaveEmailData() {
      this.loading = true;
      try {
        await this.putEmailTemplate(this.emailTemplate);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.dark-layout .ql-stroke {
  fill: none;
  stroke: #f8f8ff !important;
}
.dark-layout .ql-fill {
  fill: #f8f8ff !important;
  stroke: none;
}
.dark-layout .ql-picker {
  color: #f8f8ff !important;
}
.dark-layout .ql-picker-item {
  color: black;
}
</style>
